$(document).ready(function () {
    var dropdowns = $('input.dropdown-control-center');

    $(document).click(function (event) {
        dropdowns.each(function () {
            var input = $(this);
            var labels = $('label[for=' + input.attr('id') + ']');

            labels.each(function () {
                var label = $(this);

                if ($(event.target).parents().index(label) == -1 && !$(event.target).is(label) && !$(event.target).is(input)) {
                    if (input.is(":checked")) {
                        input.removeAttr('checked');
                    }
                }
            });
        });
    });

});