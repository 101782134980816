(function ($) {

    $.fn.objectList = function (options) {

        var settings = $.extend({
            'foo': 'bar'
        }, options);

        function rebindEvents(container) {

            container.off('change', 'tbody td.checkbox.row-selector input[type="checkbox"]');
            container.on('change', 'tbody td.checkbox.row-selector input[type="checkbox"]', function () {
                container.find('thead th.checkbox.row-selector input[type="checkbox"]').toggleChecked(false);
                selectionChanged(container);
            });

            container.off('change', 'thead th.checkbox.row-selector input[type="checkbox"]');
            container.on('change', 'thead th.checkbox.row-selector input[type="checkbox"]', function () {
                container.find('tr:visible td.checkbox.row-selector input[type="checkbox"]').toggleChecked($(this).is(':checked'));
                selectionChanged(container);
            });
        }

        function selectionChanged(container) {

            var inputs = container.find('td.checkbox.row-selector input[type="checkbox"]');
            var checked = inputs.filter(":checked");
            checked.closest('td').addClass('selected');
            inputs.not(":checked").closest('td').removeClass('selected');

            var event = jQuery.Event("selection_changed");
            event.selectedInputs = checked;
            event.selectedCount = checked.size();
            container.trigger(event);
        }

        function initObjectList(container) {

            rebindEvents(container);

            if (!container.data('is_object_list'))
                container.data('is_object_list', true);
            else
                return false;

            selectionChanged(container);
        }

        return this.each(function () {
            initObjectList($(this));
        });

    }

})(jQuery);