$.ri.controls.initializeAutocomplete = function(element) {
    element.on('click', '.autocomplete-element', function (e) {
        e.preventDefault();
        $(this).trigger('self-remove');
    });  
};

$.ri.controls.initializeLabels = function (element) {
    var selector = 'input[type="radio"], input[type="checkbox"]';

    var toggleLabel = function (input) {
        if (input.hasAttr('id')) {
            var id = input.attr('id');
            var labels = element.find('label[for="' + id + '"]:not(.dumb-label)');
        }
        else {
            var labels = input.closest('label:not([for])');

        }
        if (input.is(':checked')) {
            labels.addClass('checked');
            labels.removeClass('not-checked');
        }
        else {
            labels.removeClass('checked');
            labels.addClass('not-checked');
        }
    };

    element.find(selector).each(function () {
        toggleLabel($(this));
    });

    var changeHandler = function () {
        if ($(this).is('[type=radio]')) {
            element.find('input[name="' + $(this).attr('name') + '"]').each(function () {
                toggleLabel($(this));
            });
        }
        else {
            toggleLabel($(this));
        }
    };

    element.off('change', selector, changeHandler);
    element.on('change', selector, changeHandler);

    var xx = function (e) {
        if (e.keyCode && e.keyCode === 13) {
            var input = $('#' + $(this).attr('for'));
            input.prop('checked', true);
            input.trigger('change');
        }
    };

    element.off('keydown', 'input[type="radio"] + label', xx);
    element.on('keydown', 'input[type="radio"] + label', xx);
};

$.ri.controls.initializeSelects = function (element) {
    element.find('select').each(function () {
        var select = $(this);
        if (select.hasAttr('data-is-adapted'))
            return;

        select.attr('data-is-adapted', 'true');
        var value = select.val();

        //BUILD DROPDOWN
        $('<label class="dumb-label" for="' + select.attr('id') + '-adapter" data-adapted-id="' + select.attr('id') + '">').insertAfter(select);
        $('<input id="' + select.attr('id') + '-adapter" class="dropdown-control-center" type="checkbox"/>').insertAfter(select);
        var label = select.parent().find('label[data-adapted-id]');
        var ul = $('<ul></ul>').appendTo(label);
        select.find('option').each(function () {
            var li = $('<li><span tabindex="0" class="a" data-value="' + $(this).attr('value') + '">' + $(this).html() + '</span></li>').appendTo(ul);
            if ($(this).attr('value') == value) li.addClass('selected');
        });

        select.parent().find('label[data-adapted-id] span[data-value]').on('click', function (event) {
            var id = $(this).closest('label[data-adapted-id]').data('adapted-id');
            $('#' + id).val($(this).data('value'));
            $(this).closest('ul').find('li').removeClass('selected');
            $(this).closest('li').addClass('selected');
        });

        select.parent().find('label[data-adapted-id] span[data-value]').on('keydown', function (event) {
            if (event.keyCode !== 13) {
                return
            }

            var id = $(this).closest('label[data-adapted-id]').data('adapted-id');
            $('#' + id).val($(this).data('value'));
            $(this).closest('ul').find('li').removeClass('selected');
            $(this).closest('li').addClass('selected');
        });
    });
};

$.fn.initializeControls = function () {
    $.ri.controls.initializeDate($(this));
    $.ri.controls.initializeAutocomplete($(this));
    $.ri.controls.initializeSelects($(this));
    $.ri.controls.initializeLabels($(this));
    $.ri.controls.initializeTextarea($(this));
    $.ri.controls.initializeSortables($(this));
};