$(document).ready(function () {
    function runCallback(function_name) {
        var callback = window[function_name];
        if (typeof callback === 'function') {
            callback();
        }
        else {
            console.log(function_name + " is not a function");
        }
    }

    $('.change-state-button').on('click', function (event) {
        event.preventDefault();
        var button = $(this);
        if (button.attr('data-state') == '1') {
            $.get(button.attr('data-negative-url') + '?id=' + button.data('id'), function (data) {
                button.attr('data-state', '0');
                button.html(button.attr('data-positive-name'));
                runCallback(button.attr('data-negative-callback'));
            });
        }
        else {
            $.get(button.attr('data-positive-url') + '?id=' + button.data('id'), function (data) {
                button.attr('data-state', '1');
                button.html(button.attr('data-negative-name'));
                runCallback(button.attr('data-positive-callback'));
            });
        }
    });
});