//This script is dependent from jQuery file upload: https://github.com/blueimp/jQuery-File-Upload

function bytesToSize(bytes) {
    var sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB'];
    if (bytes == 0) return '0B';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + sizes[i];
}

function getRow(file) {
    var $name = $('<span/>', {'class': 'file-name' }).text(file.name);
    var $size = $('<span/>', {'class': 'file-size' }).text(bytesToSize(file.size));
    var $error = $('<span/>', { 'class': 'file-error' });
    var $addTag = $('<a/>', { 'class': 'add-file-tag', href: '#' }).text('Add Tag');
    var $delete = $('<a/>', {'class': 'delete-file', href: '#' }).text('Delete');
    return $('<li/>', { 'class': 'file', 'data-name': file.name }).append($name).append($size).append($error).append($delete).append($addTag);
}

function clearFileUpload(highlightIds) {
    $('.file-upload-modal').dialog('close');
    $('.index').trigger('filter_changed', { 'highlightIds': highlightIds });
}

function updateList() {
    var files = $('.files .file');
    $('.files-list-total-results').text(files.length);
    if(files.length == 0) {
        $('.button.upload').addClass('disabled');
    } else {
        $('.button.upload').removeClass('disabled');
    }
    var totalSize = 0;
    files.each(function(i, file){
        totalSize += $(file).data('data').files[0].size;
    });
    $('.file-uploader .total-size').text(bytesToSize(totalSize));

    if ($.browser.msie && parseInt($.browser.version, 10) < 10) {
        $('.file-uploader .files-total-size').hide();
        $('.files.file-list .file-size').hide();
    }
}

function initFileUpload(maxFileSize) {
    var index = 0;
    $('.max-file-size').text(bytesToSize(maxFileSize));
    $('#fileupload').fileupload({
        dataType: 'json',
        //callback after adding each file
        add: function (e, data) {
            $('.upload.button').click(function (e) {
        
                $('#fileupload')[0].disabled = true;
                e.preventDefault();
                if (data.files.length > 0) {
                    var ids = $('.tag-list').eq(index).find('.file-tag-id').serializeArray();
                    index++;
                    data.formData = ids;


                    data.submit();
                }
            });
            $.each(data.files, function (index, file) {
                $('.files').append(getRow(file).data('data', data));
            });
            updateList();
            //$.fn.modal.reposition();
        },
        stop: function (e, data) {
            var filesList = $('.file-list .file');
            var failed = 0;
            var total = filesList.length;
            var uploadedIds = [];
            $.each(filesList, function (index, file) {
                var jqXHR = $(file).data('data')._response.jqXHR;
                
                if (jqXHR.status != 200) {
                    $(file).addClass('failed').find('.file-error').text(jqXHR.statusText);
                    failed++;
                } else {
                    uploadedIds.push(parseInt(jqXHR.responseText));
                    $(file).find('a.delete-file').click();
                }
            });
            $('.progress .bar').width(0);
            $('#fileupload')[0].disabled = false;
            $('.file-uploader .statistics').text('');
            if (failed == 0) {
                clearFileUpload(uploadedIds);
                $.ri.showInfo('Files uploaded');
            } else {
                $.ri.showError(failed + ' files of ' + total + ' were not uploaded');
            }
        },
        progressall: function (e, data) {
            var progress = parseInt(data.loaded / data.total * 100, 10);
            $('.file-uploader .statistics').text(
                bytesToSize(data.loaded) + '/' + bytesToSize(data.total) + ' | ' + bytesToSize(data.bitrate / 8) + '/s'
            );
            $('.progress .bar').css('width', progress + '%');
        },
        maxFileSize: maxFileSize


    });

    if ($.browser.msie && parseInt($.browser.version, 10) < 10) {
        $('.file-uploader .files-total-size').hide();
    }

    $(document).bind('dragover', function (e) {
        var dropZone = $('#dropzone'),
            timeout = window.dropZoneTimeout;
        if (!timeout) {
            dropZone.addClass('in');
        } else {
            clearTimeout(timeout);
        }
        var found = false,
            node = e.target;
        do {
            if (node === dropZone[0]) {
                found = true;
                break;
            }
            node = node.parentNode;
        } while (node != null);
        if (found) {
            dropZone.addClass('hover');
        } else {
            dropZone.removeClass('hover');
        }
        window.dropZoneTimeout = setTimeout(function () {
            window.dropZoneTimeout = null;
            dropZone.removeClass('in hover');
        }, 100);
    });

    $('.file-uploader').on('click', '.delete-file', function (e) {
        e.preventDefault();
        $(this).closest('li').data('data').files.length = 0;
        $(this).closest('li').remove();
        updateList();
    });

    $('.upload.button').on('click', function (e) {
        e.preventDefault();
    });
}

function showUploadModal(link) {

    var uploaderHref = $(link).attr('href');
    $.get(uploaderHref, function (data) {

        $('.file-upload-modal').dialog('destroy').remove();

        var modal = $('<div style="display:none" class="file-upload-modal">' + data + '</div>').appendTo('body').dialog({
            modal: true,
            resizable: false,
            draggable: false,
            minWidth: 700,
            minHeight: 500,
            show: 'fade',
            hide: 'fade',
            dialogClass: 'ui-no-titlebar'
        });

        modal.dialog('open');
        modal.find('.close-current-modal').click(function (e) {
            e.preventDefault();
            modal.dialog('close');
        });
        $('.ui-widget-overlay').off('click').on("click", function () {
            //Close the dialog
            modal.dialog("close");
        });

        //$.fn.showModal(data);
        $.get(uploaderHref, { 'options': 'true' }, function (data) {
            initFileUpload(data.maxFileSize);
        });

    });
    
}