//offset string, css value of left property
function offsetContent(content, offset) {
    content.css('left', offset);
    $('body').css('overflow-x', 'hidden');
}
function resetOffset(content) {
    content.css('left', '0');
    $('body').css('overflow-x', 'auto');
}

var contentOffsetRight;
var contentOffsetLeft;
var tablet, mobile, widescreen, notMobile, notWidescreen = false;
var isLeftClicked = false;
var isRightClicked = false;
var isNestedClicked = false;
$(function () {

    determineDevice();
    $(window).resize(function () {

        $('.toggle-navigation').text('').attr('style', '');
        var $status = $('.identity-container .wrapper').find('.drop-status');
        $status.addClass('closed');
        $status.removeClass('opened');
        isLeftClicked = isRightClicked = false;
        determineDevice();
        resetOffset($('#main'));
        $('.top-navigation').attr('style', '');
        $('.make-dropdown-hover-button').removeClass('display-block');
        determineUserNavigation();
    });
    function determineDevice() {
        tablet = mobile = widescreen = notMobile = notWidescreen = false;

        if (document.documentElement.clientWidth < 1024 && document.documentElement.clientWidth > 480) {
            contentOffsetRight = '300px';
            contentOffsetLeft = '240px';
            tablet = true;
            notWidescreen = true;
        }
        else if (document.documentElement.clientWidth < 480) {
            contentOffsetRight = '240px';
            contentOffsetLeft = '180px';
            notWidescreen = true;
            mobile = true;
        }
        else {
            widescreen = true;
            notMobile = true;
        }
    }


    $('.nested .dropdown-trigger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('first-level-menu-hover');
        var $status = $(this).find('.drop-status');
        if (isNestedClicked) {
            $status.addClass('closed');
            $status.removeClass('opened');
            isNestedClicked = false;

            $('#use-as').css('visibility', 'hidden').css('opacity', 0); //.height(0);
            $('#use-as li').height(0);
        }
        else {
            $status.addClass('opened');
            $status.removeClass('closed');
            isNestedClicked = true;
            $('#use-as').css('visibility', 'visible').css('opacity', 1); //.height(nestedListHeight);

            $('#use-as li').each(function (idx, li) {
                li = $(li);
                var link = li.find('a');

                link.height('auto');
                li.height(link.outerHeight());
            });
        }
    });

    determineUserNavigation();
    function determineUserNavigation() {
        $('#use-as').css('visibility', 'hidden').css('opacity', '0');
        $('#use-as li').height(0);
        if (isRightClicked) {
            toggleRightNavigation();
        }
        $('.identity-container .wrapper').off('click');
        $('.identity-container').off('mouseleave');
        if (notWidescreen) {
            $('.identity-container .wrapper').on('click', function (e) {
                userMobileNavigation(e);
            });
        }
        if (notMobile) {
            $('.identity-container').on('mouseleave', function () {
                isNestedClicked = false;
                $('.nested .dropdown-trigger').removeClass('first-level-menu-hover');
                $('.nested .dropdown-trigger .drop-status').addClass('closed').removeClass('opened');
                $('#use-as').css('visibility', 'hidden').css('opacity', '0')
                $('#use-as li').height(0);
            });
        }

    }
    var $mainContent = $('#main');

    $('.toggle-navigation').on('click', function (e) {
        e.preventDefault();
        resetOffset($mainContent);
        if (isRightClicked) {
            toggleRightNavigation();
        }
        toggleLeftNavigation();

    });

    function userMobileNavigation(e) {
        e.preventDefault();
        resetOffset($('#main'));

        if (isLeftClicked) {
            toggleLeftNavigation();
        }
        toggleRightNavigation();
    }

    function toggleRightNavigation() {
        var nameWidth = $('.identity-name').css('width');
        var $status = $('.identity-container .wrapper').find('.drop-status');
        if (isRightClicked) {
            $status.addClass('closed');
            $status.removeClass('opened');
            isRightClicked = false;
            $('.wrapper-image').attr('style', '');
            $('.identity-name').attr('style', '');
            $('.make-dropdown-hover-button').css('right', '-' + contentOffsetRight);

        }
        else {
            $status.addClass('opened');
            $status.removeClass('closed');
            isRightClicked = true;
            offsetContent($mainContent, '-' + contentOffsetRight);
            $('.identity-name').css('display', 'inline-block').css('width', nameWidth);
            $('.wrapper-image').css('padding', '0');
            $('.make-dropdown-hover-button').css('right', 0);
        }
    }

    function toggleLeftNavigation() {
        if (isLeftClicked) {
            $('.navigation-container .top-navigation').css('left', '-' + contentOffsetLeft);
            $('.navigation-container .toggle-navigation').removeClass('opened-indicator').addClass('menu-closed-indicator');
            isLeftClicked = false;
        }
        else {
            isLeftClicked = true;
            $('.navigation-container .top-navigation').css('left', 0);
            $('.navigation-container .toggle-navigation').addClass('opened-indicator').removeClass('menu-closed-indicator');
            offsetContent($mainContent, contentOffsetLeft);
        }
    }
});

$(document).ready(function () {
    var x1 = $('.identity-container');
    var x = $('.identity-container .wrapper');
    var o = false;
    x.click(function () {
        o = !o;
        x1.toggleClass('open');
        x1.attr('aria-expanded', o);
    });

    x.keydown(function (e) {
        if (e.keyCode && e.keyCode === 13) {
            o = !o;
            x1.toggleClass('open');
            x1.attr('aria-expanded', o);
        }
    });

    $(document).click(function (e) {
        var target = $(e.target)
        var tt = false;

        while (target.length) {
            if (target.hasClass('identity-container')) {
                tt = true;
                break;
            }

            target = target.parent()
        }

        if (!tt) {
            x1.removeClass('open');
        }
    })
})
