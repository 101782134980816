$.fn.initializeIndexes = function () {

    function changeFilterChanged(event) {
        //        if ($(this).data('triggered_filter_change'))
        //            return; //i've just triggered it with enter pressed
        //        $(this).closest('.index').trigger('filter_changed');
        triggerFilterChanged($(this).closest('.index'));
    }

    function keypressFilterChanged(event) {
        //        if (e.keyCode == 13) {
        //            $(this).data('triggered_filter_change', 1);
        //            $(this).closest('.index').trigger('filter_changed');
        //        } else {
        //            $(this).data('triggered_filter_change', null);
        //        }
        //        return e.keyCode != 13;
        if (event.keyCode == 13) {
            triggerFilterChanged($(this).closest('.index'));
        }
    }

    function triggerFilterChanged(index) {
        $.doTimeout(index.data('link-name'), 350, function () {
            index.trigger('filter_changed');
        }, true);
    }

    function highlightResults(container, ids) {
        var results = container.find('.object-list');
        if (results.size() == 0 && results.is('.object-list'))
            results = container;
        var color = '#33b3a8';
        $.each(ids, function (i, id) {
            $('.object-list-item[data-id="' + id + '"]').effect('highlight', { 'color': color }, 2000, function () {
                $(this).addClass('highlight');
            });
        });
    }

    function updateResultsInformation(event) {
        var totalResults = parseInt($(this).find('.object-list .results-page').first().data('pager-total-results'));
        var totalPages = parseInt($(this).find('.object-list .results-page').first().data('pager-total-pages'));
        $(this).find('.object-list-total-results').html(totalResults).parent().fadeIn();
        $(this).closest('form').find('span[data-role="total-pages"]').html(totalPages);
    }

    $(this).find('.index').each(function () {
        var container = $(this);
        if (container.hasAttr('data-index-initialized')) return;

        container.attr('data-index-initialized', true);

        //invalidating filter on input change
        $('body').on('keywords_filter_more', function (e) {
            container.off('change', '.object-filter :input, .object-list-sort :input', changeFilterChanged);
            container.on('change', '.object-filter :input, .object-list-sort :input', changeFilterChanged);
        });

        container.on('change', '.object-filter :input, .object-list-sort :input', changeFilterChanged);

        //invalidating filter on enter pressed
        container.on('keypress', '.object-filter :input, .object-list-sort :input', keypressFilterChanged);

        container.on('results_updated', updateResultsInformation);

        var objects_list_form = container.find('.object-list-form').eq(0);
        if (objects_list_form.hasClass('form-with-validation')) {
            container.on('results_updated', function () {
                $.validator.reAddValidator(objects_list_form)
            });
        }

        container.trigger('results_updated');

        container.find('.object-results > .object-statistics').replaceWith(container.find('.object-list .object-statistics'));

        container.on("filter_changed", function (event, options) {
            var container = $(this);
            var url = container.find('.object-filter').first().attr('data-filter-form-action');
            var settings = $.extend({ highlightIds: [] }, options);



            $.post(url, container.find('.object-filter :input, .object-list-sort .sort-fields :input, input[name=SelectState]').serialize(), function (data) {
                container.find('.object-list .results-page').remove();
                container.find('.object-list').append(data);
                container.find('.object-results > .object-statistics').replaceWith(container.find('.object-list .object-statistics'));
                container.find('.object-list').objectList();

                container.trigger('results_updated');
                highlightResults(container, settings.highlightIds);

                $('body').trigger('filter_changed_end');
            });
        });

        container.on("export_json", function (event, options) {
            var container = $(this);
            var url = container.find('.object-filter').first().attr('data-filter-download-action');

            $.post(url, container.find('.object-filter :input, .object-list-sort .sort-fields :input, input[name=SelectState]').serialize(), function (response) {

                $.fn.showModal(response, { appendTo: 'body' });
                //if (response.url && response.data) {
                //    $.post(response.url, response.data, function (response) {
                //        if (response.result) {
                //            $.ri.showInfo(response.result)
                //        }
                //    });
                //}
                //window.location = response
            })
        });
    });
};

$(document).ready(function () {
    $('body').initializeIndexes();
});