/// <reference path="../jquery-1.7.js" />
/// <reference path="../jquery.validate.js" />
/// <reference path="../jquery.validate.unobtrusive.js" />
 
// Originally by XHalent, @xhalent
// http://xhalent.wordpress.com/
// http://xhalent.wordpress.com/2011/01/24/applying-unobtrusive-validation-to-dynamic-content/
 
// Modified by Joel Purra
// http://joelpurra.com/
// https://gist.github.com/gists/2360001

(function ($) {
    $.validator.unobtrusive.parseDynamicContent = function (selector) {
        var $selector;

        if (selector instanceof jQuery) {
            $selector = selector;
        }
        else {
            $selector = $(selector);
        }

        if ($selector.length == 0) {
            return;
        }

        //use the normal unobstrusive.parse method
        $.validator.unobtrusive.parse($selector);

        //get the relevant form
        var form = $selector.first().closest('form');

        //get the collections of unobstrusive validators, and jquery validators
        //and compare the two
        var unobtrusiveValidation = form.data('unobtrusiveValidation');
        var validator = form.data('validator');

        $.each(unobtrusiveValidation.options.rules, function (elname, elrules) {
            if ($("[name='" + elname + "']").length == 0) {
                delete validator.settings.rules[elname];
                delete unobtrusiveValidation.options.rules[elname];
            }
            else if (validator.settings.rules[elname] == undefined) {
                var args = {};
                $.extend(args, elrules);
                args.messages = unobtrusiveValidation.options.messages[elname];
                //edit:use quoted strings for the name selector
                $("[name='" + elname + "']", $selector).rules("add", args);
            } else {
                $.each(elrules, function (rulename, data) {
                    if (validator.settings.rules[elname][rulename] == undefined) {
                        var args = {};
                        args[rulename] = data;
                        args.messages = unobtrusiveValidation.options.messages[elname][rulename];
                        //edit:use quoted strings for the name selector
                        $("[name='" + elname + "']", $selector).rules("add", args);
                    }
                });
            }
        });
    },
    $.validator.reAddValidator = function (element) {
        $.validator.unobtrusive.parseDynamicContent(element);
    },
    $.validator.defaults.ignore = "",
        $.validator.checkErrorForAll = function (message) {
        var sections = ($('form').find('.tab-content'));
        for (var i = 0; i < sections.length; i++) {
            var section = ($('.tabs').find('li'))[i];
            var tab = ($('form').find('.tab-content'))[i];
            var span = $(tab).find('span.field-validation-error').length;

            $(section).validate();
            if (span > 0) {
                $(section).addClass('error');
                var divHeader = $('h2').children('div.header-error');
                if (divHeader.length === 0) {
                    $('h2').append('<div id="header-error" class="header-error">' + message + '</div>');
                }
            }
        }
        if ($('.title-labels').length > 0) {
            $('#header-error').css("vertical-align", "sub");
        }
    };
    $.validator.CheckKeyUp = function () {
        var sections = ($('form').find('.tab-content'));
        var tabSelected = $('.tab-content:visible').find('span.field-validation-error').length;
        if (tabSelected === 0) {
            $('.tabs').find('.selected').removeClass('error');
        }
        var isError = ($('form').find('.tab-content')).find('span.field-validation-error').length;

        if (isError === 0) {
            $('#header-error').remove();
        }
    },

    //validator for urls without http
    $.validator.addMethod("shorturl", function (val, elem) {
        // if no url, don't do anything
        if (val.length == 0) { return true; }

        // if user has not entered http:// https:// or ftp:// assume they mean http://
        if (!/^(https?|ftp):\/\//i.test(val)) {
            val = 'http://' + val; // set both the value
            //$(elem).val(val); // also update the form element
        }
        // now check if valid url
        // http://docs.jquery.com/Plugins/Validation/Methods/url
        // contributed by Scott Gonzalez: http://projects.scottsplayground.com/iri/
        return /^(https?|ftp):\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&amp;'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i.test(val);
    }, "Please enter a valid website address");

    //adding it to unobtrusive js
    $.validator.unobtrusive.adapters.addBool("shorturl");

    $.validator.methods.number = function (value, element) {
        if (value && value.replace)
            value = value.replace(",", ".");

        if (!$(element).is('[data-val-required]') && value == '')
            return true;

        return !isNaN(parseFloat(value));
    };
    $.validator.addMethod("requiredifempty",
       function (value, element) {
           if (value)
               return true;
           var dependentPropertyId = $(element).data('val-requiredifempty-requiredifempty');
           if ($('#' + dependentPropertyId, $(element).closest('form')).val())
               return true;
           return false;

       });
    $.validator.unobtrusive.adapters.addBool("requiredifempty");

    //$.validator.addMethod("unformattedstringlength",
    //    function (value, element) {
    //        var length = $(element).data('val-unformattedstringlength-unformattedstringlength');
    //        value = $('<textarea />').html(value).text().replace(/ /g, '_')
    //        if ($(value).text().length > length)
    //            return false;
    //        return true;
    //    });
    //$.validator.unobtrusive.adapters.addBool("unformattedstringlength");

    $.validator.ClearErrorMessagesForField = function (field) {
        var name = field.attr('name');
        var errorSpans = $('span[data-valmsg-for="' + name + '"].field-validation-error');
        errorSpans.addClass('field-validation-valid');
        errorSpans.removeClass('field-validation-error');
        errorSpans.html('');

        field.removeClass('input-validation-error');
        field.addClass('valid');
    };

    $.validator.addMethod("date", function (value, element) {

        if (value && value.match && value.match(/^\d{4}-\d{2}-\d{2}\s/))
            value = value.replace(" ", "T");

        return this.optional(element) || !/Invalid|NaN/.test(new Date(value));
    });


})($);



