$(document).ready(function () {
    var likeClick = function (e, state, classname, postCallback) {
        e.preventDefault();
        var $that = $(this);
        $.post($that.attr('href'), { positive: !state }, function (data, status) {
            if (status != "success")return;

            postCallback(data, $that);

            $that.addClass('unhover');
            $that.off('click');
            $that.on('click', emptyClick);

            if (state) {
                $that.removeClass(classname);
            } else {
                $that.addClass(classname);
            }
        });
    };
    
    var followClick = function(e) {
        var state = $(this).hasClass('following');
        var $that = $(this);
        likeClick.call(this, e, state, 'following', function(data) {
            if (state) {
                $that.html($that.data('alternative-text'));
                $that.removeClass('reverse');
            } else {
                $that.html($that.data('text'));
                $that.addClass('reverse');
            }
        });
    };

    var appreciateClick = function (e) {
        var state = $(this).hasClass('appreciated');
        var $that = $(this);
        likeClick.call(this, e, state, 'appreciated', function(data) {
            var title = $('.appreciation-icon').attr('title');
            var num = parseInt(title, 10);
            title = title.substring(num.toString().length, 100);
            $('.appreciation-icon').attr('title', data.appreciations + title);

            var date = $('.appreciate-date');
            date.text(data.appreciationDate);
            date.data('primary-text', data.appreciationDate);
        });
    };

    var emptyClick = function(e) {
        e.preventDefault();
    };

    function mouseEnterCallback(clickCallback) {
        $(this).off('click');
        $(this).on('click', clickCallback);
    }

    function mouseLeaveCallback() {
        console.log('leave');
        $(this).removeClass('unhover');
    }

    $(document).on('mouseenter', 'a.follow-button', function (e) {
        mouseEnterCallback.call(this, followClick);

        if ($(this).hasClass('following')) {
            $(this).html($(this).data('hover-text'));
        } else {
            $(this).html($(this).data('alternative-hover-text'));
        }
    });

    $(document).on('mouseleave', 'a.follow-button', function (e) {
        mouseLeaveCallback.call(this);

        if ($(this).hasClass('following')) {
            $(this).html($(this).data('text'));
        } else {
            $(this).html($(this).data('alternative-text'));
        }
    });

    $(document).on('mouseenter', 'a.appreciate', function (e) {
        mouseEnterCallback.call(this, appreciateClick);

        var date = $('.appreciate-date');
        if ($(this).hasClass("appreciated")) {
            date.text(date.data('alternative-text'));
        }
    });

    $(document).on('mouseleave', 'a.appreciate', function (e) {
        mouseLeaveCallback.call(this);

        var date = $('.appreciate-date');
        if ($(this).hasClass("appreciated")) {
            date.text(date.data('primary-text'));
        }
    });
});