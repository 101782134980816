function shiftDependentFields(element) {
    var li = $(element).closest('li');
    li.find('input[data-type="sort-switch"]').toggleEnabled(element.checked);
    li.find('.drag, label').toggleClass('disabled', !element.checked);
}

function turnOnSaveButton(event) {
    var sort_modal = event.data.sort_modal;
    sort_modal.find('a.close')
            .on('click', function () {
                saveSortModal($(this));
                hideSortModal($(this));
            })
            .removeClass('disabled');
    sort_modal.find('input').off('click', turnOnSaveButton);
    sort_modal.find('.ui-sortable').off('change', turnOnSaveButton);
}

function openSortModal(element) {
    var object_sort = element.closest('.object-list-sort');
    var sort_modal = object_sort.parent().find('.list-sort-modal');
    
    sort_modal.show();
    
    if (sort_modal.data('ready'))
        return;
    
    sort_modal.appendTo(element.parent());

    var sort_item = object_sort.find('.sort-item');
    var sort_fields = object_sort.find('.sort-fields');
    var ul = sort_modal.find('ul');

    object_sort.find('.sort-fields span').each(function () {
        var enabled = $(this).find('input[data-type=enabled]').val().toLowerCase() == 'true';
        var sortOrder = $(this).find('input[data-type=sort-order]').val();
        var name = $(this).find('input[data-type=name]').val();
        var displayName = $(this).find('input[data-type=display-name]').val();
        var li = $('<li></li>').appendTo(ul);
        var div = $('<div class="pack16in32"></div>').appendTo(li);
        div.append('<div class="control drag"><div class="inner"></div></div>');
        div.append('<input type="checkbox" name="' + name + '-enabled" data-field-name="' + name + '" data-type="sort-enable" checked="checked"/>');
        var switcher = $('<input type="checkbox" name="' + name + '-sort-type" class="ascdsc" data-type="sort-switch" />');
        div.append(switcher);
        div.append('<div class="pack-label"><label>' + displayName + '</label></div>');

        if (!enabled) {
            div.find('.control.drag, label').addClass('disabled');
            switcher.disable();
            div.find('input[data-type="sort-enable"]').removeAttr('checked');
        }
        switcher.check(sortOrder == "");
        switcher.attr('value', sortOrder);
        switcher.on("click", function () {
            $(this).attr('value', this.checked ? SortOrder_Ascending : SortOrder_Descending);
        });
    });

    sort_modal.find('a.cancel').each(function () {
        $(this).on('click', function () {
            hideSortModal($(this));
        });
    });

    sort_modal.find('input[type="checkbox"][data-type="sort-enable"]').on('click', function () {
        shiftDependentFields(this);
    });

    sort_modal.find('input').on('click', { sort_modal: sort_modal }, turnOnSaveButton);
    sort_modal.find('.ui-sortable').on('change', { sort_modal: sort_modal }, turnOnSaveButton);

    $('body').trigger('new_data_taken');
    sort_modal.data('ready', true);
}

function saveSortModal(element) {
    var object_sort = element.closest('.object-list-sort');
    var sort_item = object_sort.find('.sort-item').eq(0);
    var sort_fields = object_sort.find('.sort-fields');
    var sort_modal = element.closest('.dropdown-modal');
    var sorted_names = Array();
    var arr = sort_modal.find('input[data-type=sort-enable][type=checkbox]');

    arr.each(function (index, obj) {

        var fieldName = $(this).data('field-name');
        var container = sort_fields.find('[data-field-name="' + fieldName + '"]');
        var displayName = container.find("[data-type=display-name]").val();

        container.find("input[data-type=sequence]").val(index);

        if ($(this).is(':checked')) {
            var sortType = sort_modal.find('input[name="' + fieldName + '-sort-type"]').attr('value');
            container.find('input[data-type=sort-order]').attr('value', sortType);
            if (sortType == SortOrder_Ascending) {
                sorted_names.push(displayName + " &#8593");
            }
            else {
                sorted_names.push(displayName + " &#8595");
            }
            container.find("input[data-type=enabled]").val("true");
        }
        else {
            container.find("input[data-type=enabled]").val("false");
        }

        container.appendTo(container.parent());
    });
    sort_item.html(sorted_names.join(" / "));
    sort_fields.find('input').eq(0).trigger('change');
}

function hideSortModal(element) {
    element.closest('.dropdown-modal').hide();
}

$(document).ready(function () {
    $(document).on('click', '.sort-change', function (e) {
        e.preventDefault();
        openSortModal($(this));
    });
});