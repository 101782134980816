$(function () {

    //fix for using console log if developer tools are disabled in IE9
    if (!window.console) window.console = {};
    if (!window.console.log) window.console.log = function () { };
    
    //handling loading indicator on ajax reguest
    $(document).ajaxStart(function () {
        $.ri.showIndicator();
    });

    $(document).ajaxStop(function () {
        $.ri.hideIndicator();
    });

    $(document).ajaxComplete(function (event, xhr, settings) {
        var header = xhr.getResponseHeader("X-RedirectTo");
        if (header != "" && header != undefined && header != null) {
            document.location = header;
        }

        /*
        //re-parse the DOM after Ajax to enable client validation for any new form fields that have it enabled
        $.each($("form"), function (i, form) {
        $(form).removeData("validator");
        $(form).removeData("unobtrusiveValidation");
        });
        $.validator.unobtrusive.parse(document);*/

        $.each($("form"), function (i, form) {
            $.validator.unobtrusive.parseDynamicContent($(form));
        });
    });

    $(document).on('click', '.close-modal', function (e) {
        e.preventDefault();
        $.ri.closeModal();
    });

    $(document).on('click','.show-indicator',function () {
        $.ri.showIndicator();
    });

    $('ul.tabs').makeTabs();
    $('.show-in-modal').showInModal();
    $('table.list tr:even').addClass('odd');
    $('table.object-list').objectList();
    $.ri.makeStickyHeader();
    
    //Initializing controls
    $('body').initializeControls();
    $('body').on('new_data_taken', function () {
        $(this).initializeControls();
    });
    $(document).ajaxComplete($('body').initializeControls);
    $(document).on('results_updated', '.index', function () {
        $(this).find('.filter - section.active.object - list').initializeControls();
    });
});
