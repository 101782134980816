(function ($) {

    $.fn.hierarchyBrowser = function (options) {

        var settings = $.extend({
            'url': '',
            'anyElementName': 'Any',
            'autoExpandNextLevel': true,
            'inputName': 'hierarchyIds',
            'typeInputName': 'typeId',
            'columns': 9,
            'contentColumns': 3,
            'isLoadData': false,

            'nodeId': 0, //selected node id,
            'loadDataUrl': '', //load selected node url
            'onItemSelected': function () { },
            'readOnly': false
        }, options);

        function closeSelector() {
            $(document).unbind('mousedown.hierarchy-selector');
            $('.hierarchy-selector').fadeOut(300, function () {
                $(this).remove();
            });
        }


        function itemClicked(hierarchyItem) {
            if (hierarchyItem == null || hierarchyItem.size() == 0 || settings.readOnly == true)
                return;
            var container = hierarchyItem.closest('.hierarchy-container');
            var hideOnly = hierarchyItem.find('.hierarchy-selector').size() > 0;

            $('.hierarchy-selector').fadeOut(300, function () {
                $(this).remove();
            });

            if (hideOnly)
                return;
            //hiding sorting
            $('.list-sort-modal').remove();

            var parentId = hierarchyItem.attr('parent_id');
            var itemId = hierarchyItem.attr('item_id');
            var typeId = hierarchyItem.attr('type_id');
            var level = hierarchyItem.prevAll('.hierarchy-item').size();

            //getting data
            $.ri.getJSONCached(settings.url, { 'root': parentId, 'level': level, 'type_id': typeId }, function (data) {

                var width = $("div.row").outerWidth() * (settings.columns / 12);
                var widthInPx = width + "px";
                var selector = $('<div class="hierarchy-selector" style="display: none; width: ' + widthInPx + ';"><h3 class="modal-title">' + data.GroupName + '</h3><ul class="modal-content checkbox-list"></ul></div>');

                //adding "any" item
                selector.find('.modal-content').append('<li has_children="0">'
                    + '<label class><input class="width-1 float-left" name="hierarchy_level_' + data.Level + '" type="radio" value="0"/><div class="float-left">' + settings.anyElementName + '</div></label>'
                    + '</li>');
                //adding real items
                $.each(data.Children, function (i, obj) {
                    selector.find('.modal-content').append('<li type_id="' + obj.TypeId + '" has_children="' + (obj.HasChildren ? '1' : '0') + '">'
                    + '<label><input class="width-1 float-left" name="hierarchy_level_' + data.Level + '" type="radio" value="' + obj.Id + '"/><div class="float-left">' + obj.Name + '</div></label>'
                    + '</li>');
                });

                //selecting current item
                selector.find('input[value=' + itemId + ']:first').attr('checked', 'checked');

                selector.find('input').change(function (e) {
                    if ($(this).is(':checked')) {
                        var newHierarchyItem = createItem($(this).val(),
                            hierarchyItem.attr('parent_id'),
                            $(this).parent().next().text(),
                            $(this).closest('li').attr('has_children') == '1',
                            container,
                            hierarchyItem.attr('type_id')
                            );

                        //updating hidden field
                        var realVal = Math.max(parseInt($(this).val()), parseInt(hierarchyItem.attr('parent_id')));
                        if (isNaN(realVal))
                            realVal = parseInt($(this).val());
                        var type = parseInt($(this).closest('li').attr('type_id'));

                        //fix for id being type id at 1st level
                        if (hierarchyItem.attr('parent_id') < 1 && type > 0)
                            realVal = 0;

                        var type = parseInt($(this).closest('li').attr('type_id'));
                        container.find('input[name="' + settings.inputName + '"],input[name="' + settings.typeInputName + '"]')
                        .remove()
                        .end()
                        .append('<input type="hidden" name="' + settings.typeInputName + '" value="' + type + '"/>')
                        .append('<input type="hidden" name="' + settings.inputName + '" value="' + realVal + '"/>')
                        .find('input:last')
                        .trigger('change');

                        if (settings.onItemSelected)
                            settings.onItemSelected($(this).val());

                        //expanding next level
                        if (settings.autoExpandNextLevel)
                            itemClicked(newHierarchyItem.next());
                    }
                });

                selector.find('label').click(function () {
                    if ($(this).find('input').is(':checked')) {
                        closeSelector();
                    }
                });
                //removing selector after outside click
                $(document).bind('mousedown.hierarchy-selector', function (e) {
                    //ignoring clicks withing the selector
                    if ($(e.target).closest('.hierarchy-selector').size() > 0 || $(e.target).is('.hierarchy-selector'))
                        return;
                    closeSelector();

                });
                selector.appendTo(hierarchyItem).fadeIn(300);
                var rowLarge1 = 96;
                if (($(hierarchyItem).find('.modal-content li').length) > 37) {
                    selector.css("width", +(10 * rowLarge1) + "px");
                    settings.contentColumns = 4;
                }
                if (($(hierarchyItem).find('.modal-content li').length <= 36) && ($(hierarchyItem).find('.modal-content li').length) > 24) {
                    selector.css("width", +(9 * rowLarge1) + "px");
                    settings.contentColumns = 3;
                }
                if (($(hierarchyItem).find('.modal-content li').length <= 24) && ($(hierarchyItem).find('.modal-content li').length) > 12) {
                    selector.css("width", +(5 * rowLarge1) + "px");
                    settings.contentColumns = 2;
                }
                if (($(hierarchyItem).find('.modal-content li').length <= 12) && ($(hierarchyItem).find('.modal-content li').length) > 0) {
                    selector.css("width", +(3 * rowLarge1) + "px");

                    settings.contentColumns = 1;
                }
                $(hierarchyItem).groupElements(settings.contentColumns);
                if (($(hierarchyItem).find('.modal-content li').length <= 12) && ($(hierarchyItem).find('.modal-content li').length) > 0) {
                    var ulHeight = ($('#ul-0').height()) + 90;
                    //selector.css("height", +ulHeight + "px");
                }
                ($(hierarchyItem).find('.modal-content li').append('<div class="clear"></div>'));
            });
        }

        function createItem(id, parentId, name, hasChildren, container, typeId) {

            //removing items that are lower in tree
            container.find('.hierarchy-item[parent_id=' + parentId + ']').nextAll('.hierarchy-item').andSelf().remove();

            var hierarchyItem = $('<div class="hierarchy-item" item_id="' + id + '" parent_id="' + parentId + '" type_id="' + typeId + '"><span class="arrow float-left"></span><a class="value" href="#">' + $.trim(name) + '</a><span class="arrow float-right"></span></div>')
                .find('a.value')
                .click(function (e) {
                    e.preventDefault();
                    $('.li-column li').remove();
                    itemClicked($(this).parent());
                }).end();

            if (id == 0)
                hierarchyItem.addClass('empty')
                .appendTo(container);
            else {
                container.find('.empty').remove();
                container.append(hierarchyItem);
                if (hasChildren)
                    createItem(0, id, settings.anyElementName, true, container, typeId);
            }
            return hierarchyItem;
        }

        function createHierarchyItem(nodeId, container) {
            if (nodeId != 0) {
                $.post(settings.loadDataUrl, { 'directNode': nodeId }, function (data) {
                    $.each(data.reverse(), function (index, value) {
                        var hierarchyItem = createItem(value.Id, value.ParentId, value.Name, 0, container, value.TypeId);
                        $(hierarchyItem).append('<input name="' + settings.inputName + '" value=' + value.Id + ' style="display:none" ></>');
                        var a = $(hierarchyItem).find('a.value');
                        $(a).replaceWith(function () {
                            return $("<span />").append($(this).contents());
                        });

                        $(container).find('.remove-file-hierarchy-row').detach();

                        $(container).append('<a href="" class="remove-file-hierarchy-row">Remove</a>');

                    });
                });
            }
        }

        function loadInitialValue(id, container) {

            var lastId = 0;
            $.post(settings.loadDataUrl, { 'directNode': id }, function (data) {
                $.each(data.reverse(), function (index, value) {
                    createItem(value.Id, value.ParentId, value.Name, 0, container, value.TypeId);
                    lastId = value.Id;
                });
            });

            //createItem(0, lastId, settings.anyElementName, true, container, 0);

        }


        return this.each(function () {
            $(this).addClass('hierarchy-container');
            if (settings.isLoadData) {
                createHierarchyItem(settings.nodeId, $(this));
            }

            if (settings.initialValue > 0 && settings.loadDataUrl != '') {
                loadInitialValue(settings.initialValue, $(this));
            }
            else {
                createItem(0, 0, settings.anyElementName, true, $(this), 0);
            }

            $(this).append('<div class="clear"></div>');
        });

    };

})(jQuery);