$.extend($.ri, {
    url: {
        baseUrl: '',
        controllerName: '',
        setBaseUrl: function (url) {
            $.ri.url.baseUrl = url ? url : '/';
        },
        setControllerName: function (controllerName) {
            $.ri.url.controllerName = controllerName;
        },
        image: function (fileId) {
            return $.ri.url.action("Image", "File", { id: fileId });
        },
        thumbnail: function (fileId, maxWidth, maxHeight) {
            return $.ri.url.baseUrl + "ThumbnailHandler.axd?" + $.param({ id: fileId, maxWidth: maxWidth, maxHeight: maxHeight });
        },
        file: function (fileId) {
            return $.ri.url.action("Download", "File", { id: fileId });
        },
        action: function (actionName, controllerName, routeValues) {
            if (!controllerName) {
                controllerName = $.ri.url.controllerName;
            }
            if (!routeValues) {
                routeValues = {};
            }

            var queryString;
            var url = $.ri.url.baseUrl + controllerName + '/' + actionName;
            if (routeValues && 'id' in routeValues) {
                url = url + '/' + routeValues['id'];
                delete routeValues['id'];
            }
            queryString = $.param(routeValues);
            
            return queryString ? url + '?' + queryString : url;
        }
    }
});