function refreshTab(tab) {

    var valueEntered = false;
    tab.find(':not(.collapsed) > :input').each(function () {
        var input = $(this);
        if (input.attr('type') == 'hidden') return;

        if (($(this).val() !== "" && ($(this).attr('type') != 'checkbox' && $(this).attr('type') != 'radio'))
            || ($(this).attr('type') == 'checkbox' && $(this).prop('checked'))
            || ($(this).attr('type') == 'radio' && $(this).prop('checked'))
            || ($(this).hasClass('ui-autocomplete-input') && $(this).closest('.filter-single-field').find('.autocomplete-list > li').length > 0)) {
            valueEntered = true;
            return false;
        }
    });
    var name = tab.data('link-name');
    var tablinks = $('a[name=' + name + ']');
    if (valueEntered) {
        tablinks.each(function () {
            $(this).parent().addClass('used');
        });
    } else {
        tablinks.each(function () {
            $(this).parent().removeClass('used');
        });
    }
}


function addFullSearchItem(fieldId) {

    var inputField = $('.filter-full-text-field .ui-full-text-input:input[name*="' + fieldId + '"] ');
    var textCount = parseInt(inputField.attr('data-index-counter')) + 1
    inputField.attr('data-index-counter', textCount);
    var inputValue = inputField.val();
    var div = $('.filter-full-text-field');
    div.append('<input name="' + fieldId + '" data-field-list-index=' + textCount + ' type="hidden" value="' + inputValue + '"/>')
    inputField.val("");
    var filteredBy = inputField.closest('.index').find('.work-filters-summary p');

    appendPill(fieldId, inputValue)

    inputField.trigger('change')



    function appendPill(fieldId, text) {
        filteredBy.append('<span class="filter-pill" data-remove-criterion  data-field-list-index="' + textCount + '" data-field-id="' + fieldId + '">' + text + '<span class="remove-criterion"></span></span>');
    }

}

function refreshFilteredBy(input, param1, param2, param3) {

    if (input.hasClass('ui-full-text-input')) {
        return;
    }


    var filteredBy = input.closest('.index').find('.work-filters-summary p');

    if (input.attr('type') == 'hidden') return;

    if (input.hasClass('ui-autocomplete-input')) {
        if (param1 === undefined || param2 === undefined) {
            console.log('param2 and param3 shouldn\' be undefined');
            return;
        }

        if (param1 === 'del') {
            filteredBy.find('span[data-field-id="' + input.attr('id') + '"]').each(function () {
                var span = $(this);
                if (span.attr('data-field-list-index') == param2) {
                    span.remove();
                }
            });
        }

        if (param1 === 'add') {
            if (param3 === undefined) {
                console.log('param3 shouldn\' be undefined');
                return;
            }

            filteredBy.append('<span class="filter-pill" data-remove-criterion data-field-id="' + input.attr('id') + '" data-field-list-index="' + param2 + '">' + param3 + '<span class="remove-criterion"></span></span>');
        }
    }
    else if (input.attr('type') != 'checkbox' && input.attr('type') != 'radio') {
        filteredBy.find('span[data-field-id="' + input.attr('id') + '"]').each(function () {
            $(this).remove();
        });
        if (input.val() != '') appendPill(input.attr('id'), input.val());
    }
    else if ((input.attr('type') == 'checkbox' && !input.hasAttr('checked'))
        || (input.attr('type') == 'radio' && !input.hasAttr('checked'))) {

        filteredBy.find('span[data-field-id="' + input.attr('id') + '"]').each(function () {
            $(this).remove();
        });

    }
    else if ((input.attr('type') == 'checkbox' && input.attr('checked'))
        || (input.attr('type') == 'radio' && input.attr('checked'))) {
        appendPill(input.attr('id'), $('label[for=' + input.attr('id') + ']').html());

    }

    function appendPill(fieldId, text) {
        filteredBy.append('<span class="filter-pill" data-remove-criterion data-field-id="' + fieldId + '">' + text + '<span class="remove-criterion"></span></span>');
    }
}

function clearAll(filters) {
    var filteredBy = filters.find('.work-filters-summary p');

    filteredBy.find('span').each(function () {
        clearFilter($(this));
    });
}

function clearFilter(spanElem) {
    var id = spanElem.attr('data-field-id');
    var input = $('#' + id);
    if (input.attr('type') == 'hidden') return;

    if (input.hasClass('ui-full-text-input')) {
        var itemIndex = spanElem.attr('data-field-list-index');
        var hiddenInput = $(':input[data-field-list-index=' + itemIndex + ']')
        hiddenInput.remove();
        spanElem.remove();
        input.trigger('change');
        return;
    }
    if (input.hasClass('ui-autocomplete-input')) {
        var listIndex = spanElem.attr('data-field-list-index');
        input.trigger('remove-item-from-list', [listIndex]);
        return;
    }

    if (input.attr('type') == 'checkbox') input.removeAttr('checked');
    else if (input.attr('type') == 'radio') input.removeAttr('checked');
    else input.val('');
    input.trigger('change');
}




$(document).ready(function () {
    /**** BIND EVENTS *****/
    $('.object-filter .tab-content').on('change', function () {
        refreshTab($(this));
    });

    var handleFilterInputChange = function (event, param1, param2, param3) {
        refreshFilteredBy($(this), param1, param2, param3);
    };

    var handleAddFullSearchItem = function (event) {
        var name = event.target.id;
        addFullSearchItem(name);
    }

    $('body').on('keywords_filter_more', function (e) {
        $('.object-filter .tab-content :input').off('change', handleFilterInputChange).on('change', handleFilterInputChange);
    });

    $('.object-filter .tab-content :input ').on('change', handleFilterInputChange);

    $(document).on('click', '[data-remove-criterion]', function () {
        clearFilter($(this));
    });

    $(document).on('click', '.work-clear-all-filter', function () {
        clearAll($('.objects-summary'));
    });

    $('.object-filter .filter-single-field :input').on('keyup', function () {
        var input = $(this);
        $.doTimeout(input.attr('name'), 350, function () {
            input.trigger('change');
        }, true);
    });

    $('.object-filter .filter-full-text-field :input').on('keyup', function (event) {
        if (event.key === "Enter") {
              var input = $(this);
            addFullSearchItem(input.attr('name'));
        }
    });

    $('.object-filter .filter-full-text-field :input').off('change', handleFilterInputChange);
    $('.object-filter .filter-full-text-field :input').each(function () {
        $(this).attr('data-index-counter', 0);
    });
    $('.object-filter .filter-full-text-field :button').on('click', handleAddFullSearchItem);
    /**** INITIALIZIE FILTER TABS *****/
    $('.object-filter .tab-content').each(function () {
        refreshTab($(this));
    });

    $('.object-filter .tab-content :not(.collapsed) > :input.ui-autocomplete-input').each(function () {
        var autocomplete = $(this);
        var tryFind = autocomplete.next('ul').find('li')
        if (tryFind.length < 1) {
            tryFind = autocomplete.closest('div').find('ul').find('li');
        }
        tryFind.each(function () {
            var id = $(this).find(':input[name$=".Id"]').eq(0).val();
            var name = $(this).find(':input[name$=".Name"]').eq(0).val();
            autocomplete.trigger('change', ['add', id, name]);
        });
    });


    //hack for mobile hack
    $('#dropdown-mobile-show-filter-tabs').on('change', '', function (e) { e.stopPropagation() });

    function RemoveClassUnhover(event) {
        $(event.target).removeClass('unhover');
        $(event.target).off('mouseleave', RemoveClassUnhover);
    }

    //Remember some inputs are used for dropdowns
    $(document).on('click', 'label[for]', function () {
        var label = $(this);
        var input = $('#' + label.attr('for'));
        if (!input.hasClass('dropdown-control-center')) {
            if (!input.hasAttr('checked')) {
                label.addClass('unhover');
                label.on('mouseleave', RemoveClassUnhover);
            }
        }
    });
});