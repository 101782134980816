$.fn.disable = function () {
    this.toggleEnabled(false);
    return this;
}
$.fn.enable = function () {
    this.toggleEnabled(true);
    return this;
}

$.fn.toggleEnabled = function (state) {
    this.each(function () {
        var jThis = $(this);
        var s = state;
        if (s === undefined) {
            s = jThis.attr('disabled') == 'disabled';
        } 

        if (s)
            jThis.removeAttr('disabled');
        else
            jThis.attr('disabled', 'disabled');
        jThis.toggleClass('disabled', !s);

        //this sucks - it should be all uniform
        if (jThis.hasAttr('data-customized-control') && jThis.attr('type') == 'date') {
            var i = jThis.prev('.date').find('input');
            if (s) 
                i.removeAttr('disabled', 'disabled');
            else
                i.attr('disabled', 'disabled');
        }
        else if (jThis.hasAttr('data-customized-control')) {
            jThis.parent('.control').toggleClass('disabled', !s);
        }
        $('label[for="' + this.id + '"]').toggleClass('disabled', !s);

    });
    return this;
}

$.fn.toggleChecked = function (state, triggerOnChange) {
    var boxes = this.filter('input[type="checkbox"],input[type="radio"]:first');
    
    if (triggerOnChange)
        changing = boxes.filter(function () {
            return this.checked != state;
        });
    var changed = [];
    for (var i = 0; i < boxes.length; i++) {
        var box = boxes.get(i);
        var newState = state === undefined ? !box.checked : state;
        if (box.checked != newState)
            changed.push(box);
        box.checked = newState;
        var jBox = $(box);
        if (jBox.data('customized-control')) {
            jBox.parent()
            .toggleClass('set', newState)
            .toggleClass('notset', !newState);
        }
    }
    if (triggerOnChange)
        $(changed).trigger('change');
    return this;
}
$.fn.check = function(triggerOnChange) {
    this.toggleChecked(true, triggerOnChange);
    return this;
}

$.fn.uncheck = function(triggerOnChange) {
    this.toggleChecked(false, triggerOnChange);
    return this;
}


$.fn.selectValue = function (value, triggerOnChange) {
    var selects = this.filter('select');
    if (!$.isArray(value))
        value = [value];
    for (var i = 0; i < selects.length; i++) {
        var select = selects.get(i);
        var selected = false;
        var indexes = [];
        $.each(select.options, function (i, opt) {
            var shouldSelect = ($.inArray(opt.value, value) >= 0) && (select.multiple || !selected);
            opt.selected = shouldSelect;
            selected |= shouldSelect;
            if (shouldSelect)
                indexes.push(opt.index);
        });
        if (indexes.length == 0) {
            select.selectedIndex = 0;
            index.push(0);
        }
        var jSelect = $(select);
        if (jSelect.data('customized-control')) {
            jSelect.prev('.options').children('li').each(function () {
                var idx = $(this).data('index');
                $(this).toggleClass('selected', $.inArray(idx, indexes)>=0);
            });
            $.ri.controls.setSelectText(jSelect.parent(), select.options[indexes[0]].text);
        }
        if (triggerOnChange)
            jSelect.trigger('change');
    }
    return this;
}

$.fn.setSelectOptions = function (options) {
    return this._setSelectOptions(options, true);
}
$.fn.addSelectOptions = function (options) {
    return this._setSelectOptions(options, false);
}

$.fn._setSelectOptions = function (options, replace) {
    var selects = this.filter('select');
    if ($.isPlainObject(options)) {
        var a = [];
        for (var p in options) {
            a.push({ key: p, value: options[p] });
        }
        options = a;
    }
    if (!$.isArray(options))
        return this;
    var keyProp = 'key';
    var valueProp = 'value';
    if (options.length > 0) {
        var o = options[0];
        if (o.key === undefined && o.value !== undefined && o.text !== undefined) {
            keyProp = 'value';
            valueProp = 'text';
        }
    }
    for (var i = 0; i < selects.length; i++) {
        var select = selects.get(i);
        while (replace && select.options.length) {
            select.remove(0);
        }
        $.each(options, function (i, v) {
            select.add(new Option(v[valueProp], v[keyProp]));
        })
        var jSelect = $(select);
        if (jSelect.data('customized-control')) {
            var ul = jSelect.prev('.options');
            ul.empty();
            $.each(options, function (i, v) {
                var li = $('<li></li>').text(v[valueProp]).attr('title', v[valueProp]).data('index', i);
                ul.append(li);
            })
        }
    }
    if (replace && options.length>0)
        selects.selectValue(options[0][keyProp]);
    return this;
}



$.ri.controls = {
    checkedControlClick: function (event) {
        var wrapper = event.data.wrapper;
        event.stopPropagation();
        if (wrapper.hasClass("disabled")) {
            return;
        }

        if (event.data.customInput) {
            event.data.input.click();
        } else {
            $.ri.controls.toggleCheck(wrapper);
        }
    },

    toggleCheck: function (element) {
        if (element.hasClass('checkbox')) {
            var checked = element.hasClass('set');
            element.toggleClass('set', !checked);
            element.toggleClass('notset', checked);
        }
        else if (element.hasClass('radio')) {
            if (element.hasClass('notset')) {
                $('*[name="' + element.children('input').attr('name') + '"]')
                    .parent().removeClass('set').addClass('notset');
                element.removeClass('notset');
                element.addClass('set');
            }
        }
    },


    closeDropdownButton: function (element) {
        element.find('.options').each(function () {
            $(this).hide();
            element.find('.button').removeClass('selected');
        });
    },

    openDropdownButton: function (element) {
        element.find('.options').each(function () {
            $(this).show();
            element.find('.button').addClass('selected');
        });
    },

    changeDropdownButtonState: function (element) {
        element.find('.options').each(function () {
            if ($(this).is(':visible'))
                $.ri.controls.closeDropdownButton(element);
            else
                $.ri.controls.openDropdownButton(element);
        });
    },

    changeSelectedFieldForDropdownButton: function (element) {
        var button = element.closest('.dropdown-button');
        button.find('li:eq(0)').removeClass('selected').appendTo(button.find('ul.options'));
        element.addClass('selected').detach().appendTo(button.find('div.button:first'));
    },


    initializeCheckboxes: function (element) {
        element.find('input[type="checkbox"]').each(function () {
            $.ri.controls.initializeChecked($(this), 'checkbox');
        });
    },

    initializeRadios: function (element) {
        element.find('input[type="radio"]').each(function () {
            $.ri.controls.initializeChecked($(this), 'radio');
        });
    },

    initializeChecked: function (element, wrapperClass) {
        if (!element.hasAttr('data-customized-control')) {
            element.hide();
            element.attr('data-customized-control', 'true');
            element.wrap('<div class="control ' + wrapperClass + '"></div>');
            element.after('<div class="inner"></div>');
            var wrapper = element.parent();
            wrapper.addClass(element.get(0).checked ? "set" : "notset");

            if (element.hasAttr('readonly') || element.hasAttr('disabled'))
                wrapper.addClass('disabled');

            if (element.hasAttr('data-control-class')) wrapper.addClass(element.data('control-class'));
            wrapper.addClass(element.get(0).className);
            element.removeClass();
        } else {
            var wrapper = element.parent('.control').add(element.prev('.control'));
        }
        if (!element.data('customized-control-event')) {
            element.data('customized-control-event', true);
            wrapper.on('click', { wrapper: wrapper, customInput: true, input: element }, this.checkedControlClick);
            element.on('click', { wrapper: wrapper, customInput: false, input: element }, this.checkedControlClick);
        }

    },

    initializeDate: function (element) {
        element.find('input[type="date"]').each(function () {
            if (!$(this).hasAttr('data-customized-control')) {
                var timeValue = $(this).val();
                if (timeValue.length === 0) {
                    timeValue = "YYYY-MM-DD";
                }
                if (timeValue.length < 11) {
                    var year = timeValue.substring(0, 4);
                    var month = timeValue.substring(5, 7);
                    var day = timeValue.substring(8, 10);
                    $(this).hide();
                    $(this).attr('data-customized-control', 'true');
                    var id = $(this).attr('id');
                    $('<div class="date"><label for="' + id + '_Year" class="sr-only">Year</label><input type="text" class="date-year" maxlength="4" value="' + year + '" id="' + id + '_Year" />	<span class="date-char">/</span><label for="' + id + '_Month" class="sr-only">Month</label><input type="text" class="date-month" maxlength="2" value="' + month + '" id="' + id + '_Month" /><span class="date-char">/</span><label for="' + id + '_Day" class="sr-only">Day</label><input type="text" class="date-day" maxlength="2" value="' + day + '" id="' + id + '_Day" /></div>').data('date', timeValue).insertBefore($(this));
                }
            }
        });

        element.on('focus', 'input[class^="date-"]', function () {
            if ($(this).val() == $(this).data('default') || !$(this).data('default')) {
                $(this).data('default', $(this).val());
                $(this).val('');
            }
        });

        element.on('blur', 'input[class^="date-"]', function () {
            if ($(this).val() == '') $(this).val($(this).data('default'));
        });

        element.on('keydown', 'input[class^="date-"]', function (e) {
            if (e.keyCode <= 46 /* control keys */
             || (e.keyCode >= 48 && e.keyCode <= 57) /* digits */
             || (e.keyCode >= 96 && e.keyCode <= 105)) /* numpad digits */{
                return true;
            }
            return false;
        });

        var addZerosPrefix = function (val, p) {
            var toAdd = p - val.length;
            for (var i = 0; i < toAdd; i++)
                val = '0' + val;

            return val;
        };

        element.on('keyup', 'div.date :input', function (e) {
            var input = $(this);
            var control = input.closest('div.date');

            //browsers with input type=date support(chrome, opera) prevents to set value as fake date
            //http://jsfiddle.net/DfkU5/25/
            //so date is stored in data-date attribute of control
            var date = control.data('date');

            if (!date) {
                if (control.find('.date-hour').size() > 0)
                    date = 'yyyy-mm-dd hh:mm';
                else date = 'yyyy-mm-dd';
            }

            var valueWithPrefix;
            if (input.hasClass('date-year'))
                valueWithPrefix = addZerosPrefix(input.val(), 4);
            else
                valueWithPrefix = addZerosPrefix(input.val(), 2);

            if (input.hasClass('date-year'))
                date = valueWithPrefix + date.substring(4, 100);
            else if (input.hasClass('date-month'))
                date = date.substring(0, 5) + valueWithPrefix + date.substring(7, 100);
            else if (input.hasClass('date-day'))
                date = date.substring(0, 8) + valueWithPrefix + date.substring(10, 100);
            else if (input.hasClass('date-hour'))
                date = date.substring(0, 11) + valueWithPrefix + date.substring(13, 100);
            else if (input.hasClass('date-minute'))
                date = date.substring(0, 14) + valueWithPrefix;

            control.data('date', date);
            control.next().val(date); //sets date of true date input
        });
    },

    initializeDateTime: function (element) {
        element.find('input[type="datetime"]').each(function () {
            if (!$(this).hasAttr('data-customized-control')) {
                var timeValue = $(this).val();
                if (timeValue.length === 0) {
                    timeValue = "YYYY-MM-DD HH:MM";
                }
                var year = timeValue.substring(0, 4);
                var month = timeValue.substring(5, 7);
                var day = timeValue.substring(8, 10);
                var hour = timeValue.substring(11, 13);
                var minute = timeValue.substring(14, 16);
                if (timeValue.length > 11) {
                    $(this).hide();
                    $(this).attr('data-customized-control', 'true');
                    element = $('<div class="date"> <input type="text" maxlength="4", class="date-year" value="' + year + '" /> <span class="date-char">/</span><input type="text" maxlength="2" class="date-month" value="' + month + '" /> <span class="date-char">/</span><input type="text" class="date-day" maxlength="2" value="' + day + '" /> <input type="text" class="date-hour" maxlength="2" value="' + hour + '" /> <span class="time-char"> : </span><input type="text" class="date-minute" maxlength="2" value="' + minute + '" />  </div>').data('date', timeValue).insertBefore($(this));
                }
            }
        });
    },

    closeSelectField: function (element) {
        var options = element.children('.options');
        if (!options.is(':visible'))
            return;
        options.hide();
        element.children('.control.select').removeClass('selected');
        $(document).off("mousedown.dropDownDocMouseDown");
    },

    openSelectField: function (element) {
        var options = element.children('.options');
        if (options.is(':visible'))
            return;
        $(document).data('opened-dropdown', element);
        $(document).on("mousedown.dropDownDocMouseDown", this.dropDownDocMouseDown);
        options.show();
        element.children('.control.select').addClass('selected');
    },

    changeSelectFieldState: function (element) {
        var drop = element.children('.options');
        if (drop.is(':visible'))
            this.closeSelectField(element);
        else
            this.openSelectField(element);
    },

    changeSelectedField: function (item) {
        item.siblings().removeClass('selected');
        item.addClass('selected');
        var parent = item.closest('.control.select-container');
        var select = parent.children('select');
        var option = select.children('option').eq(parseInt(item.data('index'), 10));
        select.val(option.attr('value'));

        // set the value displayed by the newly created control
        this.setSelectText(parent, item.text());
        this.closeSelectField(parent);
        select.trigger('change');
    },
    setSelectText: function (element, text) {
        var display = element.children('.control.select').first().children('.text').first();
        if ($(display).is('input')) {
            display.val(text);
        }
        else {
            display.html(text);
        }
    },

    addHintStringDropDown: function (element) {
        var optionsLi = $(element).find('ul.options li');
        for (var i = 0; i < optionsLi.length; i++) {
            var li = optionsLi[i];
            var liText = li.innerHTML;
            $(li).attr('title', liText);
        }
    },

    initializeSelects: function (element) {
        element.find('select').each(function () {
            //Editable select isn't select, editable select is autocomplete input
            if ($(this).closest('span[data-editable-dropdown="True"]').length > 0) return;

            if ($(this).hasAttr('data-customized-control'))
                return;
            $(this).hide();
            $(this).attr('data-customized-control', 'true');
            var activeOption = null;
            var options = $('<ul class="options"></ul>');
            $(this).find('option').each(function () {
                var option = $('<li>' + (this.text != '' ? this.text : '&nbsp;') + '</li>');
                if (this.selected) {
                    activeOption = this.text;
                    option.addClass('selected');
                }
                option.data('index', this.index);
                options.append(option);
            });
            if (activeOption == null) activeOption = $(this).children().first().html() || '';

            var controltype, dropTrigger;
            var bindHybrid = $(this).parent().data('bind-hybrid');
            var hybrid = $(this).data('hybrid') ||
                ($(this).parent().data('hybrid') != null) ? $(this).parent().data('hybrid').toLowerCase() == "true" : false;
            if (hybrid) {
                if (bindHybrid == "True") {
                    controltype = '<input class="text" type="text" name="Config" value="' + activeOption + '" data-hybrid="true" data-filtering="false">';
                }
                else {
                    controltype = '<input class="text" type="text" value="' + activeOption + '" data-hybrid="true" data-filtering="false">';
                }
                dropTrigger = '.arrow';
            }
            else {
                controltype = '<div class="text" data-hybrid="false">' + activeOption + '</div>';
                dropTrigger = '.control.select';
            }
            var element = $('<div class="control select-container"><div class="control select">' + controltype + '<div class="arrow"></div></div></div>').insertBefore($(this));
            options.hide();
            element.append(options);
            $(this).detach().appendTo(element);
            $.ri.controls.addHintStringDropDown(element);

            element.find(dropTrigger).on('click', function (e) {
                if ($(this).parent().hasClass('disabled'))
                    return;
                var input = $(this).parent().find('input.text');
                if (input.data('filtering')) {
                    input.data('filtering', false);
                    $(this).closest('.select-container').find('li').show();
                }
                $.ri.controls.changeSelectFieldState(element);
            });
            options.on('click', function (e) {
                $.ri.controls.changeSelectedField($(e.target));
            });

            var input = element.find('input[type="text"].text');
            input.data('control', element);
            input.on('keyup', this.dropDownEditKey)
                .on('focus', this.dropDownEditFocus)
                .on('blur', this.dropDownEditBlur);

            if (hybrid)
                options.on('mousedown', function (e) {
                    input.data('initial', null);  //will cause on blur to exit gracefully
                });

            $.ri.controls.moveClass($(this));

        });


    },

    dropDownDocMouseDown: function (e) {
        var opened = $(document).data('opened-dropdown');
        if (!opened)
            return;
        var select = $(e.target).add($(e.target).parents()).filter('.control.select-container');
        if (select.length > 0 && (select.get(0) == opened.get(0)))
            return;
        $.ri.controls.closeSelectField(opened);
    },
    dropDownEditFocus: function () {
        $(this).data('initial', this.value);
    },
    dropDownEditKey: function () {
        $(this).data('filtering', true);
        var value = $(this).val().toLowerCase();
        var control = $(this).data('control');
        control.find('li').each(function () {
            $(this).toggle($(this).text().toLowerCase().indexOf(value) >= 0);
        });
        $.ri.controls.openSelectField(control);
    },
    dropDownEditBlur: function () {
        var initial = $(this).data('initial');
        if (!initial)
            return;
        var control = $(this).data('control');
        var val = this.value;
        var options = control.find('.options li');
        var matching = options.filter(function () {
            var t = $(this).text();
            return t == val || t == initial;
        });
        if (matching.length == 0)
            matching = options;
        $.ri.controls.changeSelectedField(matching.first());
    },


    initializeSortables: function (element) {
        element.find('.ui-sortable').each(function () {
            $(this).sortable({
                'placeholder': 'drop-placeholder',
                'handle': '.control.drag',
                'start': function (e, ui) {
                    ui.placeholder.css('height', ui.item.height());
                    ui.item.closest('.ui-sortable').addClass('is-sorting');
                },
                'stop': function (e, ui) {
                    ui.item.closest('.ui-sortable').removeClass('is-sorting');
                },
                helper: function (e, ui) {
                    ui.children().each(function () {
                        $(this).width($(this).width());
                    });
                    return ui;
                },
                update: function (e, ui) {
                    ui.item.closest('.ui-sortable').trigger('change');
                }
            });
        });
    },

    initializeDropdownButtons: function (element) {
        element.find('ul.make-dropdown-button').each(function () {
            var ul = $(this);
            var default_option = ul.children('li').eq(0).detach();

            var classes = ul.attr('class').replace("make-dropdown-button", '');
            var dropdown_button = $('<div class="dropdown-button ' + classes + '"><div class="button"></div><div class="arrow-container"><div class="arrow"></div></div><ul class="options"></ul></div>').insertBefore(ul);
            dropdown_button.find('.button').append(default_option);

            dropdown_button.find('ul.options').each(function () {
                $(this).append(ul.find('li').detach());
            });

            ul.remove();

            dropdown_button.on('click', '.arrow-container', function (e) {
                $.ri.controls.changeDropdownButtonState($(this).closest('.dropdown-button'));
            });
            dropdown_button.on('click', '.options > li', function (e) {
                $.ri.controls.changeSelectedFieldForDropdownButton($(this));
                $.ri.controls.changeDropdownButtonState($(this).closest('.dropdown-button'));
            });

            $.ri.controls.closeDropdownButton(dropdown_button);
            $.ri.controls.moveClass($(this));
        });

        $(document).on('mousedown', function (e) {
            //ignoring clicks withing the selector
            if ($(e.target).closest('.dropdown-button').size() > 0 || $(e.target).is('.dropdown-button'))
                return true;

            $(document).find('.dropdown-button').each(function () {
                $.ri.controls.closeDropdownButton($(this));
            });
        });
    },

    initializeDropdownHoverButtons: function (element) {
        element.find('ul.make-dropdown-hover-button').each(function () {
            var ul = $(this);
            var default_option = ul.children('li').eq(0).html();
            ul.children('li').eq(0).remove();

            var dropdown_button = $('<div class="dropdown-button"><div class="button">' + default_option + '</div><div class="arrow-container"><div class="arrow"></div></div><ul class="options"></ul></div>').insertBefore(ul);
            dropdown_button.find('ul.options').each(function () {
                $(this).html(ul.html());
            });

            ul.remove();

            dropdown_button.on('mouseover', function () {
                dropdown_button.find('.options').show();
            });
            dropdown_button.on('mouseout', function () {
                dropdown_button.find('.options').hide();
            });

            dropdown_button.find('.options').hide();
            $.ri.controls.moveClass($(this).parent().next());
        });
    },

    initializeTextarea: function (element) {
        // auto adjust the height of

        element.find('textarea, .inline-wysiwyg').keyup(function () {
            var te = $(this);
            var content = te.val(); //textarea has value
            if (!content) content = te.get(0).innerHTML; //no value? so it's tinymce
            var fontSize = $.ri.getComputedStyle(this, "font-size");
            var lineHeight = $.ri.getComputedStyle(this, "line-height");
            var clone_te = te.clone().attr('contenteditable', 'false').appendTo('body').css('font-size', fontSize).css('line-height', lineHeight).css('height', 'auto').css('visibility', 'hidden').css('position', 'relative').css('top', '-3000px').html(content);
            te.css('height', clone_te.get(0).offsetHeight);
            clone_te.remove();
        });

        element.find('textarea, .inline-wysiwyg').trigger('keyup').css('overflow', 'hidden');
    },

    initIE8placeholder: function () {
        $('input[placeholder]').each(function () {
            var input = $(this);
            var nofocuscolor = 'rgb(124, 170, 240)', focuscolor = 'rgb(255, 255, 255)';

            $(input).val(input.attr('placeholder')).css('color', nofocuscolor);
            $(input).focus(function () {
                if (input.val() == input.attr('placeholder')) {
                    input.val('').css('color', focuscolor);
                }
            });

            $(input).blur(function () {
                if (input.val() == '' || input.val() == input.attr('placeholder')) {
                    input.val(input.attr('placeholder')).css('color', nofocuscolor);
                }
            });
        });
    },

    initlteie8: function () {
        if (!$.browser.msie || parseInt($.browser.version, 10) >= 9) {
            return;
        }
        $.ri.controls.initIE8placeholder();
    },

    moveClass: function (control) {
        var classes = control.attr('class');
        if (classes == null)
            return;
        var target = control.parent().children().first();
        target.addClass(classes);
        control.removeClass();
    },

    initializeAutocomplete: function (element) {
        element.on('click', 'a.delete-autocomplete-element', function (e) {
            e.preventDefault();
            $(this).parent().trigger('self-remove');
        });
    }

}

$.fn.initializeControls = function () {
    $.ri.controls.initializeCheckboxes($(this));
    $.ri.controls.initializeRadios($(this));
    $.ri.controls.initializeSelects($(this));
    $.ri.controls.initializeDropdownButtons($(this));
    $.ri.controls.initializeDropdownHoverButtons($(this));
    $.ri.controls.initializeSortables($(this));
    $.ri.controls.initializeDateTime($(this));
    $.ri.controls.initializeDate($(this));
    $.ri.controls.initializeAutocomplete($(this));
    if ($.browser.msie) {
        $.ri.controls.initlteie8();
    }
    else {
        $.ri.controls.initializeTextarea($(this));
    }
};

$.widget("ri.fieldobserver", {
    options: {
        observedId: ''
    },
    
    _create: function () {
        this._on($('#' + this.options.observedId), {
            keyup: "_onObservableKeyUp"
        });
    },

    _destroy: function () {
        this._off($('#' + this.options.observedId), 'keyup');
    },

    _onObservableKeyUp: function(event)
    {
        $(this.element).text($(event.target).val());
    }
});