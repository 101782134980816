$.extend($.ptp, {
    collection: {
        moveAddButton: function(collectionObject)
        {
            collectionObject.JQElement.find('.add-object-to-list').remove();
            if(collectionObject.JQElement.find('tr:last-of-type td:last-of-type').length != 0)
                collectionObject.JQElement.find('tr:last-of-type td:last-of-type').append(collectionObject.AddButton);
            else
                collectionObject.JQElement.find('tr:last-of-type th:last-of-type').append(collectionObject.AddButton);
            collectionObject.AddButton = collectionObject.JQElement.find('.add-object-to-list').clone(true);
        },
            
        initialize: function(selector, rowInitialize)
        {
            var minObjectsNumber = 0;
                
            var collectionObject = new Object();
            collectionObject.JQElement = $(selector);
                
            collectionObject.JQElement.find('tbody tr').each(rowInitialize);
                
            collectionObject.JQElement.find('.add-object-to-list').click(function (e) {
                e.preventDefault();
                $.get($(this).attr('href'), {}, function (data) {
                    if (collectionObject.JQElement.find('tbody tr').length == minObjectsNumber)
                    {
                        collectionObject.JQElement.find('.delete-tr').closest('td').show();
                    }
                    var row = $(data).appendTo(collectionObject.JQElement.find('tbody'));
                    $.ptp.scopeInputs(row, collectionObject.JQElement.children('input[name$=".Scope"]').first().val());
                    rowInitialize.call(row.get(0));
                    $.ptp.collection.moveAddButton(collectionObject);
                });
                $('body').trigger('new_data_taken');
            });

            collectionObject.JQElement.on('click', '.delete-tr', function (e) {
                e.preventDefault();

                $(this).closest('tr').remove();

                if (collectionObject.JQElement.find('tbody tr').length == minObjectsNumber) {
                    collectionObject.JQElement.find('.delete-tr').closest('td').hide();
                }
                $.ptp.collection.moveAddButton(collectionObject);
            });

            if(collectionObject.JQElement.find('tbody tr').length == minObjectsNumber)
            {
                collectionObject.JQElement.find('.delete-tr').closest('td').hide();
            }

            collectionObject.AddButton = collectionObject.JQElement.find('.add-object-to-list').clone(true);
            $.ptp.collection.moveAddButton(collectionObject);
        }
    },
        
    showIndicator: function() {
        if ($('#loading-indicator').size() < 1)
            $('body').append('<div id="loading-indicator"><div></div></div>');
        $('#loading-indicator').show();
    },

    hideIndicator: function() {
        $('#loading-indicator').hide(0);
    },

    resizeImagesToFillContainer: function (imagesSelector, containerSelector) {
        $(imagesSelector).each(function() {
            // Create new offscreen image to test
            var theImage = new Image();

            var resizeImg = $(this);

            theImage.onload = function() {
                var containerOuterWidth = 0;
                var containerOuterHeight = 0;
                var container = resizeImg.closest(containerSelector);

                function doResizingImage() {
                    // Get accurate measurements from that.
                    var origWidth = theImage.width;
                    var origHeight = theImage.height;

                    var containerWidth = container.width();
                    var containerHeight = container.height();
                    var boundingClientRect = container.get(0).getBoundingClientRect();
                    containerOuterHeight = boundingClientRect.bottom - boundingClientRect.top;
                    containerOuterWidth = boundingClientRect.right - boundingClientRect.left;

                    var param1 = origHeight / origWidth;
                    var param2 = containerHeight / containerWidth;

                    if (param1 > param2) {
                        var widthParam = containerWidth / origWidth;
                        var height = origHeight * widthParam;
                        var width = containerWidth;
                        var left = 0;
                        var top = (containerHeight - height) / 2;
                    } else {
                        var heightParam = containerHeight / origHeight;
                        var width = origWidth * heightParam;
                        var height = containerHeight;
                        var top = 0;
                        var left = (containerWidth - width) / 2;
                    }

                    resizeImg.css({ height: height + "px", width: width + "px", left: left + "px" });

                    /*setTimeout(function() {
                        var waitClientRect = container.get(0).getBoundingClientRect();
                        if (
                            waitClientRect.bottom - waitClientRect.top !== containerOuterHeight ||
                            waitClientRect.right - waitClientRect.left !== containerOuterWidth
                        ) {
                            console.log("mini recursion");
                            doResizingImage();
                        }
                    }, 15);*/
                }

                doResizingImage();
            };
            theImage.src = resizeImg.attr("src");
        });
    },

    uploadFile: function(uploadUrl, successCallback)
    {
        if ($('#upload-file-form').length < 1) $('body').append('<form id="upload-file-form" style="display: none;" enctype="multipart/form-data"><input name="file" id="upload-file-input" type="file" /></form>');
        $('#upload-file-input').click();
        $('#upload-file-input').off('change');
        $('#upload-file-input').on('change', function()
        {
            var formData = new FormData($('#upload-file-form')[0]);
            $.ajax({
                url: uploadUrl,  //Server script to process data
                type: 'POST',
                success: successCallback,
                // Form data
                data: formData,
                //Options to tell jQuery not to process data or worry about content-type.
                cache: false,
                contentType: false,
                processData: false
            });
        });
    }
});
    



/****************** Modernizr *******************/

var Modernizr = {};
Modernizr.touch = 'ontouchstart' in window // works on most browsers
|| 'onmsgesturechange' in window; // works on ie10;

    /************* Scrolling ****************/
    function scrollToWithOffset(target) {
        var id = target.attr('id');
        target.attr('id', id + "-temp");
        window.location.hash = id;
        target.attr('id', id);
        window.scrollTo(0, target.offset().top - 100);
        $('.footnote-mark').each(function() {
            Foundation.libs.tooltip.hide($(this));
        });
    }

    function scrollToFromHref(e) {
        var href = $(e.currentTarget).attr('href');
        var name = href.substr(href.indexOf('#') + 1);
        var target = $('#' + name);
        if (target.length) {     
            e.preventDefault();
            scrollToWithOffset(target);
        }
    }
                


$(document).ready(function() {
    /************************************** Overlays **************************************/
    $(document).on('click', 'a[data-overlay-id]', function(e) {
        e.preventDefault();
        $('.active-overlay').removeClass('active-overlay').hide();
        $('#' + $(this).attr('data-overlay-id')).addClass("active-overlay").show();
    });

    $(document).on('click', 'a.hide-overlay', function(e) {
        e.preventDefault();
        $('.active-overlay').removeClass('active-overlay').hide();
    });

    /******************************** Initializing controls *******************************/
    $('.expander').each(function()
    {
        var i = 0;
        $(this).find('.checkbox-list li').each(function()
        {
            if(i >= 20)$(this).addClass('collapsed');
            i++;
        });
    });

    $('.keywords-expand-button').on('click', function (e) {
        
        e.preventDefault();

        var element = $(e.target);
        var list = element.parent().find('.checkbox-list');
        debugger
        var arg = window.keywordsArg || '';


        $.ajax({
            type: 'POST',
            url: $.ri.url.baseUrl + 'work/getFilterKeywords?'+arg,
            success: function (response) {
                if (Array.isArray(response)) {
                    list.html('');

                    for (var i = 0; i < response.length; i++) {
                        var item = response[i];
                        var itemElement = $('<li><input id="Keywords_' + item.Value + '" name="Keywords" type="checkbox" value="' + item.Value + '"><label for="Keywords_' + item.Value + '" tabindex="0" class="not-checked">' + item.Text + '</label></li>');
                        itemElement.appendTo(list);
                    }
                }

                $('body').trigger('keywords_filter_more');
                clearAll($('.objects-summary'));
                $('.work-filters-summary > p').html('');
            }
        });
    });

    $('.expand').on('click', function(e)
    {
        $(this).closest('.expander').find('.collapsed').removeClass('collapsed');
        $(this).hide();
        $(this).closest('.expander').find('.collapse').show();
        e.preventDefault();
    });
        
    $('.collapse').on('click', function(e)
    {
        var i = 0;
        $(this).closest('.expander').find('.checkbox-list li').each(function()
        {
            if(i >= 20)$(this).addClass('collapsed');
            i++;
        });
        $(this).hide();
        $(this).closest('.expander').find('.expand').show();
        e.preventDefault();
    });
        
    $('body').initializeControls();
    $('body').on('new_data_taken', function () {
        $(this).initializeControls();
    });
    $(document).ajaxComplete($('body').initializeControls);
    $(document).on('results_updated', '.index', function () {
        $(this).find('.filter - section.active.object - list').initializeControls();
    });

    $(document).on('click', '.resign-work, .accept-work, .reject-work, .delete-work, .publish-conversation', function(e)
    {
        e.preventDefault();
        var $link = $(this);
        $.fn.showConfirmationModal($link.data('message'), function()
        {
            $.get($link.attr('href'), function (data) {
                location.reload();
            });
        });
    });
  
        
    /************************* Scrolling and starting scroll position *********************/
    if ($("#page-initial-scroll-to").size() > 0) {
        var automatedGoingUp = false;
        var scrollPositionBefore = $("#page-initial-scroll-to").offset().top - 580;

        $('html, body').scrollTop(scrollPositionBefore);

        $(document).on('scroll', function() {
            $.doTimeout('scroll', 100, function() {
                var st = $(document).scrollTop();
                if (st + 1 < $("#page-initial-scroll-to").offset().top - 580 && st < scrollPositionBefore && !automatedGoingUp) {

                    automatedGoingUp = true;
                    $('html, body').animate({ scrollTop: 0 }, 500, function() {
                        scrollPositionBefore = 0;
                        automatedGoingUp = false;
                    });
                    $(document).trigger('scrolledTop');
                }
                scrollPositionBefore = st;
            }, true);
        });
    }

    if (document.location.search && ('.tab-content.index').length > 0)
    {
        if ($('.tab-content.index').length) {
            window.scrollTo(0, $('.tab-content.index').offset().top - 80);
        }
    }

    $('span.a[data-href]').on('click', function() {
        window.location.href = $(this).attr('data-href');
    });


    $('.show-in-modal').showInModal();

    $(document).on('click', '#cancel-reveal-button', function(e)
    {
        e.preventDefault();
        $.ri.closeModal();
    });

    $(document).on('focus', ":input", function() {
        $(this)
            .parent("span")
            .addClass("focus");
    });

    $(document).on('focusout', ":input", function() {
        $(this)
            .parent("span")
            .removeClass("focus");
    });

    /************************ Initialize tabs and carousel **********************/

    var objectEditForm = $('.object-edit-form');
    if (objectEditForm.length > 0) {
        objectEditForm.find('ul.tabs').makeTabs({
            'enableUrlHashSetting': true
        });
    }

    $('.object-filter ul.tabs').makeTabs({
        'enableUrlHashSetting' : true
    });

    $(document).ajaxComplete(function() {
        $('.object-filter ul.tabs').makeTabs({
            'enableUrlHashSetting': true
        });
    });
        
    $('.carousel').each(function()
    {
        var carousel = $(this);
        var itemsActivated = carousel.find('.item.active').length;
        if (itemsActivated != 1){
            carousel.find('.item.active').removeClass('active');
            carousel.find('.item').first().addClass('active');
            console.log('Carousel should have only one active item');
        }
    });

    $('.carousel').carousel({
        pause: "false",
        interval: 10000,
    });

    $('.ptpobject-cover .carousel').carousel('pause');

    $(document).on('scrolledTop', function() {
        var el = $('.ptpobject-cover').not('.object-edit-form .ptpobject-cover');
        if (el.find('.carousel .item').length > 0) {
            el.find('.carousel-control').show();
            el.find('.carousel').carousel('cycle');
        }
    });

    $(document).on('click', '.carousel-indicators li', function() {
        $('.carousel').carousel('pause');
    });

    $(document).on('click', '[data-toggle]', function(e) {
        e.preventDefault();
        $this = $(this);
        $parent = $this.closest($this.data('scope'));
        //$($this.data('hide'), $parent).hide();
        //$($this.data('show'), $parent).show();
        $($this.data('hide'), $parent).addClass('hide-force').removeClass('display-block-force');
        $($this.data('show'), $parent).removeClass('hide-force').addClass('display-block-force');

    });


    $(document).ajaxStart(function() {
        $.ptp.showIndicator();
    });

    $(document).ajaxStop(function() {
        $.ptp.hideIndicator();
    });

    $(document).ajaxComplete(function() {
        srcsetMain();
    });

    $(document).ajaxComplete(function(event, xhr, settings) {
        var header = xhr.getResponseHeader("X-RedirectTo");
        if (header != "" && header != undefined && header != null) {
            document.location = header;
        }

        $.each($("form"), function (i, form) {
            if ($.validator)
                $.validator.unobtrusive.parseDynamicContent($(form));
        });
    });




    /********** Carousel images resizing ***********/
        

    function removeShowHideClassess() {
        $('.hide-force').removeClass('hide-force');
        $('.display-block-force').removeClass('display-block-force');
    }

    function resizeImages() {
        $.ptp.resizeImagesToFillContainer('.ptpobject-cover-image.not-trick', '.ptpobject-cover .carousel');
        $.ptp.resizeImagesToFillContainer('.page-cover img.background', '.page-cover-background');
        $.ptp.resizeImagesToFillContainer('.skillshare-item img.background-image', '.skillshare-item');
        $.ptp.resizeImagesToFillContainer('.profile-logo img', '.profile-logo');
        $.ptp.resizeImagesToFillContainer('.profile-mini-logo img', '.profile-mini-logo');
    }

    resizeImages();

    $(window).resize(function() {
        $.doTimeout('resize', 350, function() {
            resizeImages();
            removeShowHideClassess();
        }, true);
    });

    function resizeProfileLogo(tile) {
        $.ptp.resizeImagesToFillContainer($(tile).find('.profile-logo img'), '.profile-logo');
    }

    $(document).on('mouseenter mouseleave', '.profile-item', function() {
        resizeProfileLogo(this);
    });

    $(document).ajaxComplete(function() {
        resizeImages();
    });

    /********************* Footnotes *************************/
    if (Modernizr.touch) {
        $(document).on('click', '.footnote-mark a[href^="#"]', function(e) { e.preventDefault(); });
        $(document).on('click', '.footnote-list a[href^="#"]', function(e) { scrollToFromHref(e); });
    } else {
        $(document).on('click', 'a[href^="#"]', function(e) { scrollToFromHref(e); });
    }

    //If you uncomment this, you'll see how editing objects doesn't work
    //TODO
//        if (document.location.hash) {
//            scrollToWithOffset($(window.location.hash));
//        }

    $('.footnote-mark').each(function() {
        var id = $(this).attr('id');
        var parent = $('[href=#' + id + ']').parent().clone(true);
        parent.find('a').eq(0).remove();
        var text = parent.html(); //.nextSibling.nodeValue;
        $(this).attr('title', text);
        $(this).attr('data-tooltip', 'data-tooltip');
    });
    $(document).foundation({ tooltip: {} });
    Foundation.libs.tooltip.settings.append_to = '.work-content';
    Foundation.libs.tooltip.settings.touch_close_text = '';
    $(document).foundation({ tooltip: {} });


    $(document).on('click', '[data-conversation-comment]', function(e) {
        e.preventDefault();
        showReplyForm($(this).closest('.conversation'));
            
        $('#Reply_IsTranslation').prop('checked', false);

    });

    $(document).on('click', '[data-conversation-translate]', function(e) {
        e.preventDefault();
        showReplyForm($(this).closest('.conversation'));

        $('#Reply_IsTranslation').prop('checked', true);
    });


    function showReplyForm($conversationForm) {
        tinymce.execCommand('mceRemoveEditor', false, 'Reply_Content');
        $("#conversation-reply").insertAfter($conversationForm).show();
        $('#Reply_TextAssetId').val($conversationForm.attr('data-id'));
        tinymce.execCommand('mceAddEditor', false, 'Reply_Content');
    }
});

$(document).ready(function () {
    $('label').on('keypress', function (event) {
        if (event.which === 13) {
            var f = $('#' + $(this).attr('for'));
            if (f.length > 0) {
                if (f.attr('type') == 'checkbox') {
                    f[0].checked = !f[0].checked;
                    $(this).parent().find('input').trigger('change');
                }
            }
        }
    });
});

$(document).ready(function () {
    var currentlyOpenWorkItem = null;

    var initWorkItemsInteractions = function () {
        setTimeout(function () {
            var workItems = $('.results-item.work-item');
            var profileItems = $('.profile-item');

            var d = function (idx, item) {
                item = $(item);
                item.unbind('click').click(function () {
                    item.toggleClass('focused');

                    if (currentlyOpenWorkItem !== null && currentlyOpenWorkItem !== item) {
                        currentlyOpenWorkItem.removeClass('focused');
                    }

                    currentlyOpenWorkItem = item;
                });
            };

            workItems.each(d);
            profileItems.each(d);
        });
    };

    initWorkItemsInteractions();

    $('body').on('filter_changed_end', function (e) {
        initWorkItemsInteractions();
    });

    $('body').on('scroll_results_updated', function () {
        initWorkItemsInteractions();
    });

    $('body').on('click', function (e) {
        if (currentlyOpenWorkItem != null) {
            var target = $(e.target);
            var clickedInsideWorkItem = false;

            while (target.length > 0) {
                if (target.hasClass('work-item') || target.hasClass('profile-item')) {
                    clickedInsideWorkItem = true;
                    break;
                }

                target = target.parent();
            }

            if (!clickedInsideWorkItem) {
                currentlyOpenWorkItem.removeClass('focused');
                currentlyOpenWorkItem = null;
            }
        }
    });
});

// --------------------------------------------------------------------------------------

$(document).ready(function () {
    PTP_REINITIALIZE_DROPDOWNS();
    
    var handleWindowScrolled = function () {
        var amount = window.pageYOffset;
        var viewportHeight = window.innerHeight;
        var viewportBottomEdgeY = amount + viewportHeight;
        
        $('[srcsetlazy]').each(function (idx, element) {
            element = $(element);
            
            if (viewportBottomEdgeY > element[0].getBoundingClientRect().top) {
                var srcsetlazy = element.attr('srcsetlazy');
                element.attr('srcsetlazy', null);
                element.attr('srcset', srcsetlazy);
            }
        });
        
        $('[srclazy]').each(function (idx, element) {
            element = $(element);
            
            if (viewportBottomEdgeY > element[0].getBoundingClientRect().top) {
                var srcsetlazy = element.attr('srclazy');
                element.attr('srclazy', null);
                element.attr('src', srcsetlazy);
            }
        });
    };
    
    $(window).on('scroll', function () {
        handleWindowScrolled();
    });
    
    $('body').on('filter_changed_end', function () {
        handleWindowScrolled();
    });
    
    handleWindowScrolled();
});

var PTP_REINITIALIZE_DROPDOWNS = function () {
    var initializeDropdown = function (root) {
        var isVisible = false;
        var lastVisible = false;
        var button = root.find('.dds20-dropdown-button');
        var items = root.find('.dds20-dropdown-items > li');
        var selectedItemIdx = 0;

        var handleButtonClick = function (e) {
            var itemsToggled = 0;

            lastVisible = isVisible;

            if (!isVisible) {
                root.addClass('dds20-dropdown--open');
                root.prop('aria-expanded', true);
            }

            for (var i = 0; i < items.length; i++) {
                var item = $(items[i]);

                if (i === selectedItemIdx) {
                    item.hide();
                    continue;
                }

                if (isVisible) {
                    item.slideUp(100, function () {
                        itemsToggled++;

                        if (lastVisible && itemsToggled === items.length) {
                            root.removeClass('dds20-dropdown--open');
                            root.attr('aria-expanded', false);
                        }
                    });
                } else {
                    item.slideDown(100, function () {
                        itemsToggled++;

                        if (lastVisible && itemsToggled === items.length) {
                            root.removeClass('dds20-dropdown--open');
                            root.attr('aria-expanded', false);
                        }
                    });
                }
            }

            isVisible = !isVisible;
        };

        button.click(function (e) {
            handleButtonClick(e);
        });

        var onItemClick = function (item, i) {
            selectedItemIdx = i;
            button.html(item.html());
            handleButtonClick();
        };

        var setupItemClickHandler = function (item, itemIndex) {
            item.click(function (e) {
                onItemClick(item, itemIndex);
            });
        };

        for (var i = 0; i < items.length; i++) {
            setupItemClickHandler($(items[i]), i);
        }
    };

    $('.dds20-dropdown').each(function (idx, element) {
        initializeDropdown($(element));
    });
};