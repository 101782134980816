$.ptp = {
    HandleAddNewExternal: function (query, url, saveUrl) {
        $(this).on('selected-non-existing-item', query, function (e) {
            var autocomplete = $(this);

            function createNewElementFromModal() {
                $.post(saveUrl, $(':input', $('.autocomplete-new-modal')).serialize(), function (data) {
                    window.onbeforeunload = null; //dont ask a user for confirmation about leaving the page
                    if (data.Item1 !== undefined && data.Item2 !== undefined && data.Item3 !== undefined)
                        autocomplete.trigger('select-with-add-item-to-list', [data.Item1, data.Item2, data.Item3]);
                });
                return true;
            }

            $.get(url, function (data) {
                $.ptp.showDialog(data, "", "", createNewElementFromModal, "", "autocomplete-new-modal");
            });
        });
    },

    scopeInputs: function (element, scope) {
        element.find('input, select, textarea').each(function () {
            if ($(this).hasAttr('name')) {
                $(this).attr('name', scope + "." + $(this).attr('name'));
            }
            if ($(this).hasAttr('id')) {
                $(this).attr('id', scope.replace('.', '_') + "_" + $(this).attr('id'));
            }
            if ($(this).hasAttr('name') && $(this).attr('name').indexOf('.Scope') != -1) {
                $(this).val(scope + "." + $(this).val());
            }
        });
    },

    showDialog: function (data, openningCallback, closingCallback, successCallback, failureCallback, className) {
        if (!openningCallback) {
            openningCallback = function () { };
        }
        if (!closingCallback) {
            closingCallback = function () { };
        }
        if (!successCallback) {
            successCallback = function () { };
        }
        if (!failureCallback) {
            failureCallback = function () { };
        }

        var modal = $('<div>' + data + '</div>').appendTo('body').dialog({
            modal: true,
            resizable: false,
            draggable: false,
            minWidth: 960,
            minHeight: 400,
            show: 'fade',
            hide: 'fade',
            dialogClass: 'ui-no-titlebar'
        });

        modal.addClass(className);

        modal.find('.close-modal').on('click', function (e) {
            e.stopPropagation();
            e.preventDefault();
            closeWithoutSuccess();
        });
        modal.find('.modal-success').on('click', function (e) {
            e.preventDefault();
            successCallback(modal);
            close();
        });
        $('.ui-widget-overlay').off('click').on("click", function () {
            closeWithoutSuccess();
        });

        function closeWithoutSuccess() {
            failureCallback(modal);
            close();
        }

        function open() {
            modal.dialog('open');
            openningCallback(modal);
        }

        function close() {
            closingCallback(modal);
            modal.dialog("close");
            modal.dialog('destroy');
            modal.remove();
        }
        open();
        return modal;
    }
}
